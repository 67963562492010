import { Notice, QueuePosition, State } from "bouncer-waitingroom";

export class WaitingRoomHandler {
    // progress bar
    private progressElement: HTMLDivElement;
    private progressBarElement: HTMLDivElement;
    private progressRunnerElement: HTMLImageElement;

    // text elements
    private panelHeaderElement: HTMLHeadingElement;
    private panelBodyTextElement: HTMLSpanElement;

    // postion info
    private positionInfoElement: HTMLSpanElement;

    // notices
    private noticeListElement: HTMLUListElement;

    // prequeue countdown
    private prequeueCountdownElement: HTMLDivElement;
    private prequeueCountdownRemainingElement: HTMLDivElement;
    private prequeueCountdownStartTimeElement: HTMLDivElement;

    // views
    private waitingContent: HTMLDivElement;
    private enterContent: HTMLDivElement;

    // state
    private state: State = new State(0, new Date(Date.now()));

    // audio
    private enterAudio = new Audio("bouncer/static/audio/Hinein_Hinein_mixdown.mp3");

    constructor() {
        this.progressElement = document.querySelector("#progress") as HTMLDivElement;
        this.progressBarElement = document.querySelector("#progress-bar") as HTMLDivElement;
        this.progressRunnerElement = document.querySelector("#progress-runner") as HTMLImageElement;

        this.panelHeaderElement = document.querySelector("#panel-header") as HTMLHeadingElement;
        this.panelBodyTextElement = document.querySelector("#panel-body-text") as HTMLHeadingElement;

        this.positionInfoElement = document.querySelector("#position-info") as HTMLSpanElement;

        this.noticeListElement = document.querySelector("#notice-list") as HTMLUListElement;

        this.prequeueCountdownElement = document.querySelector("#prequeue-countdown") as HTMLDivElement;
        this.prequeueCountdownRemainingElement = document.querySelector("#prequeue-countdown-remaining") as HTMLDivElement;
        this.prequeueCountdownStartTimeElement = document.querySelector("#prequeue-countdown-start-time") as HTMLDivElement;

        this.waitingContent = document.querySelector("#waiting") as HTMLDivElement;
        this.enterContent = document.querySelector("#enter") as HTMLDivElement;

        this.registerHandlers();

        setInterval(() => this.updateCountDown(), 1000)
    }


    private registerHandlers(): void {
        document.addEventListener("bouncer:noticesUpdated", (e) => this.handleNotices(e.detail));
        document.addEventListener("bouncer:stateUpdated", (e) => this.handleState(e.detail));
        document.addEventListener("bouncer:queuePositionUpdated", (e) => this.handleQueuePosition(e.detail));
    }

    private handleNotices(notices: Array<Notice>): void {
        if (notices.length == 0) {
            this.noticeListElement.style.display = "none";
        } else {
            let updatedNoticeList = "";

            notices.forEach(n => updatedNoticeList += `<li>${n.message}</li>`);

            this.noticeListElement.innerHTML = updatedNoticeList;
            this.noticeListElement.style.display = "block";
        }
    }

    private handleQueuePosition(queuePosition: QueuePosition | null): void {
        this.updateProgressText(queuePosition);
        this.updateProgressBar(queuePosition);
        this.updateText(queuePosition)
    }

    private handleState(state: State): void {
        this.state = new State(state.ticketExpiryMinutes, state.admissionStartTime);
        this.updateCountDown();
    }

    private updateProgressText(queuePosition: QueuePosition | null): void {
        if (queuePosition === null) {
            console.info("prequeue positionInfoElement text applied")

            this.positionInfoElement.innerText = "Du bist im Vorwarteraum. Deine Position wird Dir gleich angezeigt!"

            return;
        }

        if (queuePosition.position === 1) {
            console.info("one ticket ahead positionInfoElement text applied")

            this.positionInfoElement.innerText = "Ein Unioner ist vor Dir!"

            return;
        }

        if (queuePosition.position <= 0) {
            console.info("its this tickets turn positionInfoElement text applied")

            this.positionInfoElement.innerText = "Du bist der Nächste!"

            this.waitingContent.style.display = "none";
            this.enterContent.style.display = "block";

            // Play sound when ticket is allowed to enter
            //document.title = "Hinein, Hinein, Hinein, Hinein"
            let playAttempt = setInterval(() => {
                this.enterAudio.play().then(() => {
                    console.log("playing enter sound", this.enterAudio);
                    clearInterval(playAttempt);
                })
                .catch((error) => {
                    if (error.name === "NotAllowedError") {
                        console.warn("Unable to play the enter sound, User has not interacted yet", error, this.enterAudio);
                        clearInterval(playAttempt);
                    } else {
                        console.error("some other error related to the playback of enter sound", error, this.enterAudio);
                    }
                });
            }, 2000);

            return;
        }

        this.positionInfoElement.innerText = `${queuePosition.position} Unioner sind vor Dir!`;
    }

    private updateProgressBar(queuePosition: QueuePosition | null): void {
        // minimum value for runner to be positioned in box
        const min = 4;
        let progress = 0;

        if (queuePosition !== null) {
            // Prequeueing
            progress = queuePosition.progress;
            this.progressElement.style.display = "none";
            this.progressRunnerElement.style.display = "none";
        }

        // normalize provided progress with minimum width
        progress = (((100 - min) * progress) / 100) + min;

        const queueProgressRoundedPercentage = `${Math.round(progress)}%`;
        const queueProgressPercentage = `${progress}%`;

        this.progressElement.style.width = queueProgressPercentage;
        this.progressElement.style.display = "block";
        this.progressRunnerElement.style.display = "block";
    }

    private updateText(queuePosition: QueuePosition | null): void {
        const isQueueing = queuePosition !== null;
        if (isQueueing) {
            // show queueing text
            this.panelHeaderElement.innerText = "Sorry! Gerade ist es ziemlich voll bei uns!";
            this.panelBodyTextElement.innerText = `Du bist jetzt in der Warteschlange. Wenn Du an der Reihe bist, hast Du 15 Minuten Zeit, um den Shop zu betreten.`;

            // toggle progress and countdown
            this.progressBarElement.style.display = "block";
            this.prequeueCountdownElement.style.display = "none";
        } else {
            // show prequeue text
            this.panelHeaderElement.innerText = "Hallo Unioner,";
            this.panelBodyTextElement.innerText = "Du bist ganz schön früh dran… Hier gibt es noch nichts zu sehen und für Dich auch nichts zu tun. Mit Vorverkaufsstart wirst Du automatisch per Zufallsprinzip in die Warteschlange eingereiht.";

            // toggle progress and countdown
            this.progressBarElement.style.display = "none";
            this.prequeueCountdownElement.style.display = "grid";
        }
    }

    private updateCountDown() {
        if (this.state === undefined) {
            console.log("state not loaded yet");
            return;
        }

        this.prequeueCountdownRemainingElement.innerText = this.getRemainingTime(this.state.admissionStartTime);
        this.prequeueCountdownStartTimeElement.innerText = this.state.admissionStartTime.toLocaleTimeString();
    }

    private getRemainingTime(admissionStartTime: Date): string {
        const now = new Date(Date.now());
        const milliseconds = this.state.admissionStartTime.getTime() - now.getTime();
        const seconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);

        const remainingMinutes = minutes % 60;
        const remainingSeconds = seconds % 60;

        let remainingTime = "";
        if (hours > 0) {
            // Add hours if needed
            remainingTime += hours.toString().padStart(2, "0") + ":";
        }

        if (remainingMinutes > 0) {
            remainingTime += remainingMinutes.toString().padStart(2, "0");
        } else {
            // Set 00 because if now is passed it will be -1
            remainingTime += "00";
        }

        remainingTime += ":";

        if (remainingSeconds > 0) {
            remainingTime += remainingSeconds.toString().padStart(2, "0");
        } else {
            // Set 00 because if now is passed it will be -1
            remainingTime += "00";
        }

        return remainingTime;
    }
}
